import React, { useContext } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import AppContainer from "../Components/Structure/AppContainer";
import ZegoLogs from "../Components/CallLogs/ZegoLogs";
import TwilioLogs from "../Components/CallLogs/TwilioLogs";
import { Toaster } from "react-hot-toast";
import CallContext from "../Context/CallContext";
import CallCategoriesChart from "../Components/CallLogs/CallCategoriesChart";

function CallLogsPage() {

  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const todayFormatted = new Date().toLocaleDateString('en-US', options);
  const {totalCallsPerCategory, logsNumber, buzzVoiceCallsToday, buzzVideoCallsToday, gsmCallsToday, completedCallsToday,
    missedCallsToday, averageCallDuration, averageCallDurationToday, completedCalls, missedCalls, longestCallDuration} = useContext(CallContext);

  return (
    <AppContainer title="Call Logs">
      <Toaster position="top-center" reverseOrder={false} />
      
      <div className="row">
        <div className="col-lg-4">
          <div className="row">
            <div className="col-lg-12">
              <div className="card custom-card" style={{paddingBottom:"10px"}}>
                <div className="card-body map-card">
                  <div className="item-title">
                    <h5 style={{marginBottom:10}}>Call Stats Today ({todayFormatted})</h5>
                  </div>
                  <table className="table mb-0 mw-100 color-span">
                    <tbody>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            Total Calls{" "}
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          {logsNumber ? logsNumber.total_p : "..."}
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            Buzz Voice Calls
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          {buzzVoiceCallsToday ? buzzVoiceCallsToday.total_p : "..."}
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            Buzz Video Calls
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          {buzzVideoCallsToday ? buzzVideoCallsToday.total_p : "..."}
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            GSM Calls
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          {gsmCallsToday ? gsmCallsToday.total_p : "..."}
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            Completed Calls
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          {completedCallsToday ? completedCallsToday.total_p : "..."}
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            Missed Calls
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          {missedCallsToday ? missedCallsToday.total_p : "..."}
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            Average Call Duration
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          {averageCallDurationToday ? averageCallDurationToday : "..."}
                          </span>{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <CallCategoriesChart/>
          </div> 
        </div>
        
        <div className="col-lg-8">
          <div className="row">
            <div className="col-lg-12">
              <div className="card custom-card" style={{paddingBottom:"10px"}}>
                <div className="card-body map-card gradient-my-blue">
                  <div className="item-title mb-2" style={{color:"white"}}><b>STATISTICS </b></div>
                  <div className="row">
                    <div className="col-xl-4 col-lg-12 col-sm-6 pr-0 pl-0 border-right">
                      <div className="text-center">
                        <h2 className="mb-1 number-font" style={{color:"white"}}><span className="counter">{totalCallsPerCategory ? totalCallsPerCategory.totals.buzz_voice.total_p : "..."}</span></h2>
                        <p className="mb-0 text-light"> Buzz Voice Calls</p>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-12 col-sm-6 pr-0 pl-0 border-right">
                      <div className="text-center">
                        <h2 className="mb-1 number-font" style={{color:"white"}}><span className="counter">{totalCallsPerCategory ? totalCallsPerCategory.totals.buzz_video.total_p : "..."}</span></h2>
                        <p className="mb-0 text-light"> Buzz Video Calls</p>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-12 col-sm-6 pr-0 pl-0">
                      <div className="text-center">
                        <h2 className="mb-1 number-font" style={{color:"white"}}><span className="counter">{totalCallsPerCategory ? totalCallsPerCategory.totals.gsm.total_p : "..."}</span></h2>
                        <p className="mb-0 text-light"> GSM Calls</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>                  
            </div>
            <div className="col-lg-6">
              <div className="dashboard-summery-one mg-b-20 gradient-orange-peel">
                <div className="row align-items-center">
                  <div className="col-5">
                    <div className="item-icon-log bg-light">
                      <img alt="avatar" style={{height:"35px"}} src={process.env.PUBLIC_URL + "/assets/img/figure/phone-answer.png"}/>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="item-content">
                      <div className="item-title" style={{color:"white"}}>Completed Calls</div>
                      <div className="item-number" style={{color:"white"}}><span className="counter">{completedCalls ? completedCalls.total_p : "..."}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="dashboard-summery-one mg-b-20 gradient-orange-peel">
                <div className="row align-items-center">
                  <div className="col-5">
                    <div className="item-icon-log bg-light">
                      <img alt="avatar" style={{height:"35px"}} src={process.env.PUBLIC_URL + "/assets/img/figure/missed-call.png"}/>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="item-content">
                      <div className="item-title" style={{color:"white"}}>Missed Calls</div>
                      <div className="item-number" style={{color:"white"}}><span className="counter">{missedCalls ? missedCalls.total_p : "..."}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="dashboard-summery-one mg-b-20 gradient-orange-peel">
                <div className="row align-items-center">
                  <div className="col-5">
                    <div className="item-icon-log bg-light">
                      <img alt="avatar" style={{height:"35px"}} src={process.env.PUBLIC_URL + "/assets/img/figure/call-duration.png"}/>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="item-content">
                      <div className="item-title" style={{color:"white"}}>Average Call Duration</div>
                      <div className="item-number" style={{color:"white"}}><span className="counter">{averageCallDuration ? averageCallDuration : "..."}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="dashboard-summery-one mg-b-20 gradient-orange-peel">
                <div className="row align-items-center">
                  <div className="col-5">
                    <div className="item-icon-log bg-light">
                      <img alt="avatar" style={{height:"35px"}} src={process.env.PUBLIC_URL + "/assets/img/figure/time.png"}/>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="item-content">
                      <div className="item-title" style={{color:"white"}}>Longest Call</div>
                      <div className="item-number" style={{color:"white"}}><span className="counter">{longestCallDuration ? longestCallDuration : "..."}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mt-3">
              <div className="card height-auto">
                <div className="card-body">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                      <Col sm={12}>
                        <Nav variant="pills" className="flex-row mb-1">
                          <Nav.Item>
                            <Nav.Link size="sm" eventKey="first">
                              Buzz to Buzz Calls{" "}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link size="sm" eventKey="second">
                              Buzz to Other Networks{" "}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <ZegoLogs/>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <TwilioLogs/>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>  
                </div>
              </div>
          </div>

          </div>
        </div>

        
      </div>
      <div className="row">
        
      </div>
    </AppContainer>
  );
}

export default CallLogsPage;
