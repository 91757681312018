import React, {useContext, useEffect} from "react";
import AppContainer from "../Components/Structure/AppContainer";
import WeeklyLogsChart from "./WeeklyLogsChart";
import StudentContext from "../Context/StudentContext";
import SchoolContext from "../Context/SchoolContext";
import ContactContext from "../Context/ContactContext";
import StationContext from "../Context/StationContext";
import CallContext from "../Context/CallContext";
import DashboardStatistics from "../Components/Dashboard/DashboardStatistics";
import TransactionsContext from "../Context/TransactionsContext";
import DashboardTransactions from "../Components/Dashboard/DashboardTransactions";
import { Link } from "react-router-dom";
import AuthContext from "../Context/AuthContext";
import StatisticsContext from "../Context/StatisticsContext";

function Dashboard() {

  const {studentsNumber} = useContext(StudentContext);
  const {schoolsNumber} = useContext(SchoolContext);
  const {contactsNumber} = useContext(ContactContext);
  const {totalCallsPerCategory, averageCallDuration, completedCalls, missedCalls} = useContext(CallContext);
  const {user} = useContext(AuthContext);
  const {buzzChatsPerCategory, averageChatSize, gsmCount, averageGSMSize} = useContext(StatisticsContext);

  const {dashboardTransactions, recentPmTransactions, getRecentPMTransactions, getDashboardTransactions} = useContext(TransactionsContext);
 
  useEffect(() => {
    getDashboardTransactions();
    getRecentPMTransactions();
  }, []);
  
  return (
    <AppContainer title={"Dashboard"}>
      <div class="row row-sm">
        <div class="col-sm-12 col-lg-12 col-xl-8">
        
          <div class="row row-sm mt-lg-1">
            <div class="col-sm-12 col-lg-12 col-xl-12">
              <div class="card custom-card card-box" style={{height:"150px"}}>
                <div class="card-body gradient-orange-peel p-4">
                  <div class="row align-items-center">
                    <div
                      class="offset-xl-3 offset-sm-6 col-xl-8 col-sm-6 col-12 img-bg"
                    >
                      <h4 class="d-flex mb-3">
                        <span class="font-weight-bold text-white"
                          >{user.full_name}!</span
                        >
                      </h4>
                      <p class="mb-2" style={{color:"white"}}>
                        Here is an overview of the statistics from the different modules connected with Buzztime.
                       
                      </p>
                    </div>

                    <img
                      src={process.env.PUBLIC_URL + "/assets/img/figure/laptop.jpg"}
                      alt="user-img"
                      style={{position:"absolute", height:"120px"}}
                    />
                  </div>
                </div>
              </div>
            </div>
            <DashboardStatistics schoolsNumber={schoolsNumber} contactsNumber={contactsNumber} studentsNumber={studentsNumber}/>
            <div className="col-lg-6">
        <div className="card custom-card" style={{paddingBottom:"10px"}}>
          <div className="card-body map-card">
            <div className="item-title">
              <h4 style={{marginBottom:10}}>Call Statistics</h4>
            </div>
            <table className="table mb-0 mw-100 color-span">
              <tbody>
                <tr>
                  <td className="py-2 px-0">{" "}
                    <span className="w-50">
                      Buzz Voice Calls{" "}
                    </span>{" "}
                  </td>
                  <td>:</td>
                  <td className="py-2 px-0">{" "}
                    <span className="">
                    {/* {totalCallsPerCategory ? totalCallsPerCategory.totals.buzz_voice.total_p : "..."} */}
                    </span>{" "}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 px-0">{" "}
                    <span className="w-50">
                      Buzz Video Calls
                    </span>{" "}
                  </td>
                  <td>:</td>
                  <td className="py-2 px-0">{" "}
                    <span className="">
                    {totalCallsPerCategory ? totalCallsPerCategory.totals.buzz_video.total_p : "..."}
                    </span>{" "}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 px-0">{" "}
                    <span className="w-50">
                      GSM Calls
                    </span>{" "}
                  </td>
                  <td>:</td>
                  <td className="py-2 px-0">{" "}
                    <span className="">
                    {totalCallsPerCategory ? totalCallsPerCategory.totals.gsm.total_p : "..."}
                    </span>{" "}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 px-0">{" "}
                    <span className="w-50">
                      Completed Calls{" "}
                    </span>{" "}
                  </td>
                  <td>:</td>
                  <td className="py-2 px-0">{" "}
                    <span className="">
                    {completedCalls ? completedCalls.total_p : "..."}
                    </span>{" "}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 px-0">{" "}
                    <span className="w-50">
                      Missed Calls
                    </span>{" "}
                  </td>
                  <td>:</td>
                  <td className="py-2 px-0">{" "}
                    <span className="">
                    {missedCalls ? missedCalls.total_p : "..."}
                    </span>{" "}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 px-0">{" "}
                    <span className="w-50">
                      Average Call Duration
                    </span>{" "}
                  </td>
                  <td>:</td>
                  <td className="py-2 px-0">{" "}
                    <span className="">
                    {averageCallDuration ? averageCallDuration : "..."}
                    </span>{" "}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 px-0">{" "}
                    <Link className="w-50" to="/call-logs"><small>
                      View all Call Statistics <i className="fa fa-angles-right mr-2"></i></small>
                    </Link>{" "}
                  </td>
                  <td></td>
                  <td className="py-2 px-0">{" "}
                    <span className="">
                    
                    </span>{" "}
                  </td>
                </tr>
                
              </tbody>
            </table>
          </div>
        </div>
      
      
            </div>
            <div className="col-lg-6">
              <div className="card custom-card" style={{paddingBottom:"10px"}}>
                <div className="card-body map-card">
                  <div className="item-title">
                    <h4 style={{marginBottom:10}}>Message Statistics</h4>
                  </div>
                  <table className="table mb-0 mw-100 color-span">
                    <tbody>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            Buzz Text Chats{" "}
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          {buzzChatsPerCategory ? buzzChatsPerCategory.totals.buzz_text.total_p : "..."}
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            Buzz Voice Notes
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          {/* {buzzChatsPerCategory ? buzzChatsPerCategory.totals.buzz_voice.total_p : "..."} */}
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            GSM Messages
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          {gsmCount ? gsmCount.total_p : "..."}

                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            Buzz Video Messages{" "}
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          {buzzChatsPerCategory?.totals?.buzz_video?.total_p ? buzzChatsPerCategory.totals.buzz_video.total_p : "00"}
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            Average Chat Size
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          {averageChatSize ? averageChatSize.total_p : "..."} characters
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            Avg. GSM text size
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          {averageGSMSize ? averageGSMSize.total_p : "..."} characters
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <Link className="w-50" to="/buzztime-charges/list"><small>
                            View all Message Statistics <i className="fa fa-angles-right mr-2"></i></small>
                          </Link>{" "}
                        </td>
                        <td></td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          
                          </span>{" "}
                        </td>
                      </tr>
                      
                    </tbody>
                  </table>
                </div>
              </div>
          </div>
          </div>
          

          
          
        </div>
            
        <div class="col-sm-12 col-lg-12 col-xl-4 mt-xl-4">
          <div class="row row-sm">
            <div class="col-sm-12 col-lg-12 col-xl-12">
              <div class="card custom-card card-dashboard-calendar pb-0">
              <div className="card-body map-card">
                <label class="main-content-label mb-2 pt-1">Recent MM Transactions</label>
                <span class="d-block tx-12 mb-2 text-muted">List of recent mobile money transactions</span>
                <table class="table table-hover m-b-0 transcations">
                  <tbody>
                  {Array.isArray(dashboardTransactions) && dashboardTransactions.map((item, key) => (
                    <tr key={key}>
                      <td>{item.created_at?.short_date}<br/><small>{item.created_at?.time}</small></td>
                      <td>{item.user}<br/><small>{item.username}</small></td>
                      <td><span  class="badge bg-teal"><i class="fa fa-circle text-teal fs-9px fa-fw me-5px" style={{color:"#042954"}}></i>UGX. {item.amount}</span><br/>
                                            <span className={`badge ${item.status === "3" ? "badge-success" : item.status === "1" ? "badge-warning" : "badge-danger"}`}>
                                            {item.status === "3" ? "SUCCESSFUL" : item.status === "1" ? "PENDING" : "FAILED"}
                                            </span>
                                        </td> 
                      
                    </tr>
                  ))}
                  
                   
                  </tbody>
                  
                </table>
                <Link className="w-50" to="/mm/list"><small>
                            View all Mobile Money Transactions <i className="fa fa-angles-right mr-2"></i></small>
                          </Link>{" "}</div>
              </div>
            </div>
          </div>
            <div class="row row-sm mt-lg-4">
            <div class="col-sm-12 col-lg-12 col-xl-12">
              <div class="card custom-card card-dashboard-calendar pb-0">
              <div className="card-body map-card">
                <label class="main-content-label mb-2 pt-1">Recent Pocket Money Transactions</label>
                <span class="d-block tx-12 mb-2 text-muted">List of recent pocket money transactions</span>
                <table class="table table-hover m-b-0 transcations mt-2">
                  <tbody>
                  {Array.isArray(recentPmTransactions) && recentPmTransactions.map((item, key) => (
                    <tr key={key}>
                      <td>{item.created_at?.short_date}<br/><small>{item.created_at?.time}</small></td>
                      <td>
                      {item.transactors.length === 2 ? (
                        <>
                          {item.transactors[0].cash_in === "0"? <i className="fa fa-angles-down mr-2" style={{color:"red"}}></i>:<i className="fa fa-angles-up mr-2" style={{color:"green"}}></i>} {item.transactors[0].user} <br/>
                          {item.transactors[1].cash_in === "0"? <i className="fa fa-angles-down mr-2" style={{color:"red"}}></i>:<i className="fa fa-angles-up mr-2" style={{color:"green"}}></i>} {item.transactors[1].user}
                        </>
                      ) : (
                        <>
                        {item.transactors[0].cash_in === "0"? <i className="fa fa-angles-down mr-2" style={{color:"red"}}></i>:<i className="fa fa-angles-up mr-2" style={{color:"green"}}></i>}<span> {item.transactors[0].user}</span>
                        </>
                      )}
                      </td>
                      <td class="text-end">
                      <span  class="badge bg-teal"><i class="fa fa-circle text-teal fs-9px fa-fw me-5px" style={{color:"#042954"}}></i>UGX. {item.amount}</span><br/>
                      <span className="badge badge-info">{item.account || "N/A"}</span>
                      </td>
                    </tr>
                  ))}
                    
                  </tbody>
                </table>
                <Link className="w-50" to="/pocket-money/list"><small>
                            View all Pocket Money Transactions <i className="fa fa-angles-right mr-2"></i></small>
                          </Link>{" "}</div>
              </div>
            </div>

          </div>
          
        </div>
      </div>
        
        

        <div class="row gutters-20">
          {/* 

          <DashboardTransactions transactionList={dashboardTransactions}/>  */}

          
          {/* <WeeklyLogsChart/> */}
          
          {/* <div className="col-lg-5">
              <div className="card custom-card" style={{paddingBottom:"10px"}}>
                <div className="card-body map-card">
                  <div className="item-title">
                    <h4 style={{marginBottom:10}}>Transaction Statistics</h4>
                  </div>
                  <table className="table mb-0 mw-100 color-span">
                    <tbody>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            Pocket Money Deposits{" "}
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          00
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            Buzztime Purchases
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          00
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            Account Activations
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          00
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            Call Charges{" "}
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          00
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            Buzz Chat Charges
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          00
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <span className="w-50">
                            Vendor Float Load
                          </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          00
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">{" "}
                          <Link className="w-50"><small>
                            View all Transaction Statistics <i className="fa fa-angles-right mr-2"></i></small>
                          </Link>{" "}
                        </td>
                        <td></td>
                        <td className="py-2 px-0">{" "}
                          <span className="">
                          
                          </span>{" "}
                        </td>
                      </tr>
                      
                    </tbody>
                  </table>
                </div>
              </div>
            
            
          </div> */}

          
          

        </div>
      </AppContainer>
    );
  }

export default Dashboard;