import React, { useEffect, useState } from 'react';
import ajaxBank from '../util/remote/ajaxBank';
import ajaxVendor from '../util/remote/ajaxVendor';

const VendorContext = React.createContext();

export const VendorConsumer = VendorContext.Consumer;

export const VendorProvider = (props)=> {
  const [vendorId, setVendorId] = useState(null);
  const [vendorUserId, setVendorUserId] = useState(null);

  const [vendorProfile, setVendorProfile] = useState(false);
  const [vendorWalletBalance, setVendorWalletBalance] = useState(false);
  const [vendorTransactions, setVendorTransactions] = useState([]);
 
  const [loading2,setLoading2] = useState(false)


  useEffect(() => {
    if (vendorId) {
      getVendorProfile();
    }
  }, [vendorId]);

  useEffect(() => {
    if (vendorUserId) {
      getVendorWalletBalance();
      getVendorWalletTransactions();

    }
  }, [vendorUserId]);

  const getVendorProfile =async()=>{
    if (!vendorId) return;
    const server_response = await ajaxVendor.fetchVendorInfo(vendorId);
    if(server_response.status==="OK"){
      setVendorProfile(server_response.details);
    }else{
      setVendorProfile("404");
    }
  }

  const getVendorWalletBalance = async () => {
    if (!vendorUserId) return;
    setVendorWalletBalance(false)
    const server_response = await ajaxBank.fetchPocketMoneyBalance(vendorUserId);
    
    if (server_response.status === "OK") {
      setVendorWalletBalance(server_response.details);
    } else {
      setVendorWalletBalance('0');
    }
  };

  const getVendorWalletTransactions = async () => {
    if (!vendorUserId) return;
    setLoading2(true);
    const server_response = await ajaxBank.fetchUserPocketMoneyTransactions(vendorUserId);
    setLoading2(false);
    if (server_response.status === "OK") {
      setVendorTransactions(server_response.details);
    } else {
      //communicate error
      setVendorTransactions([]);
    }
  };
    
    return (
           <VendorContext.Provider value={
               {
                    vendorProfile,
                    vendorTransactions,
                    vendorWalletBalance,
                   
                    loading2,
                    vendorId,
                    vendorUserId,
                    setVendorUserId,
                    setVendorId,
      
                  getVendorProfile,
                  getVendorWalletTransactions
               }
               }>
               {props.children}
           </VendorContext.Provider>
        );
    
}

export default VendorContext;