import React, {useContext, useEffect} from "react";
import {Link} from "react-router-dom";
import AppContainer from "../../../../Components/Structure/AppContainer";
import AdminContext from "../../../../Context/AdminContext";
import useStateCallback from "../../../../util/customHooks/useStateCallback";
import TableHeader from "../../../../Components/Common/TableHeader";
import toast, {Toaster} from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import RemoteLogOut from "../../../RemoteLogOut";
import ResetPassword from "../../../../Components/SystemUsers/ResetPassword";
import AddVendor from "../../../../Components/Vendors/AddVendor";
import Loader from "../../../../Components/Common/Loader";

function ListVendorsPage() {

    const {vendorList, getVendorList, loading} = useContext(AdminContext);
    const [modal, setModal] = useStateCallback(false);

    useEffect(() => {
        getVendorList();
      }, []);

    const handleAdd=()=>{
      setModal(false, ()=>setModal(<AddVendor g={getVendorList}  isOpen={true}/>))
    }

    const remoteLogout = (e, item) => {
      setModal(false, () =>
        setModal(
          <RemoteLogOut
            userID={item.user_id}
            isOpen={true}
          />
        )
      );
    };

    const handleUpdate=(e,item)=>{
        setModal(false, ()=>setModal(<ResetPassword accountID={item.account_id} isOpen={true}/>))
      }
  return (
    <AppContainer title="Vendors">
      <Toaster position="top-center" reverseOrder={false} />

    {modal}
      <div className="row">
        <div className="col-lg-12 col-md-12 mt-3">
        <div className="card height-auto">
   
      <div className="card-body">
        <TableHeader
            title="Vendors List"
            subtitle="List of all the different vendors" 
            viewButton={
                <a href="#" onClick={handleAdd} className="btn btn-info" style={{float:"right"}}>Add Vendor</a>
                   
            }   
              />
        {/* Search form */}
        {/* Table */}
        <div className="border-top mt-3"></div>
        <div className="table-responsive">
          {loading ? (
                              <Loader /> 
                          ) : (
          <table className="table display data-table text-nowrap">
            <thead>
              <tr>
                <th>ID</th>
                <th>Names</th>
                <th>Username</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
                    {Array.isArray(vendorList) && vendorList.map((item, key) => (
                        <tr>
                          <td>{key + 1}</td>
                          <td><Link to={`/vendors/profile/${item.vendor_id}/${item.user_id}`}>{item.full_name}
                              </Link></td>
                          <td>{item.username}</td>
                          <td>
                            <div className="dropdown">
                              <Link
                                to="#"
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false">
                                <span className="flaticon-more-button-of-three-dots"></span>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-right">
                                <Link
                                  className="dropdown-item"
                                  to="#"
                                  onClick={(e) => handleUpdate(e,item)}>
                                  <i className="fas fa-cogs text-dark-pastel-green"></i>
                                  Reset Password
                                </Link>
                                <Link
                              className="dropdown-item"
                              to="#"
                              onClick={(e) => remoteLogout(e,item)}>
                              <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ color: "red", marginRight: "3px" }} />
                                Remote Log-Out
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                      {vendorList === "404" && (<tr>
                          <td colSpan="3" style={{textAlign: "center"}}>
                            No vendors registered yet.
                          </td>
                        </tr>)}
                  </tbody>
          </table>)}
        </div>
      </div>
    </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default ListVendorsPage;
