import React, { useEffect, useState } from 'react';
import ajaxCallStation from '../util/remote/ajaxCallStation';
import { get } from 'jquery';

const CallContext = React.createContext();

export const CallConsumer = CallContext.Consumer;

export const CallProvider = (props)=> {

    const [twilioLogsList, setTwilioLogsList] = useState([]);
    const [zegoLogsList, setZegoLogsList] = useState([]);

    const [twilioStartDate, setTwilioStartDate] = useState("");
    const [zegoStartDate, setZegoStartDate] = useState("");

    const [twilioEndDate, setTwilioEndDate] = useState("");
    const [zegoEndDate, setZegoEndDate] = useState("");

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const [twilioPage,setTwilioPage] = useState(1)
    const [zegoPage,setZegoPage] = useState(1)

    const [twilioMeta,setTwilioMeta] = useState([])
    const [zegoMeta,setZegoMeta] = useState([])

    const [twilioCaller, setTwilioCaller] = useState("");
    const [zegoCaller, setZegoCaller] = useState("");

    const [twilioCallee, setTwilioCallee] = useState("");
    const [zegoCallee, setZegoCallee] = useState("");

    const [isTwilioSearching, setIsTwilioSearching] = useState(false);
    const [isZegoSearching, setIsZegoSearching] = useState(false);

    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isRealTimeEnabled, setIsRealTimeEnabled] = useState(true);
    const [logsNumber, setLogsNumber] = useState(false);
    const [logsThisWeek, setLogsThisWeek] = useState(false);
    const [logsThisMonth, setLogsThisMonth] = useState(false);
    const [totalCallsPerCategory, setTotalCallsPerCategory] = useState(false);
    const [buzzVoiceCallsToday, setBuzzVoiceCallsToday] = useState(true);
    const [buzzVideoCallsToday, setBuzzVideoCallsToday] = useState(true);
    const [gsmCallsToday, setGsmVoiceCallsToday] = useState(true);
    const [completedCallsToday, setCompletedCallsToday] = useState(true);
    const [missedCallsToday, setMissedCallsToday] = useState(true);
    const [averageCallDuration, setAverageCallDuration] = useState(true);
    const [averageCallDurationToday, setAverageCallDurationToday] = useState(true);
    const [completedCalls, setCompletedCalls] = useState(true);
    const [missedCalls, setMissedCalls] = useState(true);
    const [longestCallDuration, setLongestCallDuration] = useState(true);

    useEffect(() => {
      getLogsNumber();
      getLogsThisMonth();
      getLogsThisWeek();
      getCallsPerCategory();
      getBuzzVoiceCallsToday();
      getBuzzVideoCallsToday();
      getGsmCallsToday();
      getMissedCallsToday();
      getCompletedCallsToday();
      getAverageCallDuration();
      getAverageCallDurationToday();
      getCompletedCalls();
      getMissedCalls();
      getLongestCallDuration();
    }, []);

    const getLogsNumber = async () => {
      const server_response = await ajaxCallStation.countLogsToday();
      if (server_response.status === "OK") {
        setLogsNumber(server_response.details);
      } else {
        setLogsNumber("404");
      }
    };
  
    const getLogsThisMonth = async () => {
      const server_response = await ajaxCallStation.countLogsThisMonth();
      if (server_response.status === "OK") {
        setLogsThisMonth(server_response.details);
      } else {
        setLogsThisMonth("404");
      }
    };

    const getBuzzVoiceCallsToday = async () => {
      const server_response = await ajaxCallStation.countBuzzVoiceCallsToday();
      if (server_response.status === "OK") {
        setBuzzVoiceCallsToday(server_response.details);
      } else {
        setBuzzVoiceCallsToday("404");
      }
    };

    const getBuzzVideoCallsToday = async () => {
      const server_response = await ajaxCallStation.countBuzzVideoCallsToday();
      if (server_response.status === "OK") {
        setBuzzVideoCallsToday(server_response.details);
      } else {
        setBuzzVideoCallsToday("404");
      }
    };

    const getGsmCallsToday = async () => {
      const server_response = await ajaxCallStation.countGSMCallsToday();
      if (server_response.status === "OK") {
        setGsmVoiceCallsToday(server_response.details);
      } else {
        setGsmVoiceCallsToday("404");
      }
    };
  
    const getCompletedCallsToday = async () => {
      const server_response = await ajaxCallStation.countCompletedCallsToday();
      if (server_response.status === "OK") {
        setCompletedCallsToday(server_response.details);
      } else {
        setCompletedCallsToday("404");
      }
    };

    const getMissedCallsToday = async () => {
      const server_response = await ajaxCallStation.countMissedCallsToday();
      if (server_response.status === "OK") {
        setMissedCallsToday(server_response.details);
      } else {
        setMissedCallsToday("404");
      }
    };

    const getCompletedCalls = async () => {
      const server_response = await ajaxCallStation.countCompletedLogs();
      if (server_response.status === "OK") {
        setCompletedCalls(server_response.details);
      } else {
        setCompletedCalls("404");
      }
    };

    const getMissedCalls = async () => {
      const server_response = await ajaxCallStation.countMissedLogs();
      if (server_response.status === "OK") {
        setMissedCalls(server_response.details);
      } else {
        setMissedCalls("404");
      }
    };

    const getLogsThisWeek = async () => {
      const server_response = await ajaxCallStation.countLogsThisWeek();
      if (server_response.status === "OK") {
        setLogsThisWeek(server_response.details);
      } else {
        setLogsThisWeek("404");
      }
    };

    const getCallsPerCategory = async () => {
      const server_response = await ajaxCallStation.countLogsPerCategory();
      if (server_response.status === "OK") {
        setTotalCallsPerCategory(server_response.details);
      } else {
        setTotalCallsPerCategory("404");
      }
    };

    const getAverageCallDuration = async () => {
      const server_response = await ajaxCallStation.averageCallDuration();
      if (server_response.status === "OK") {
        setAverageCallDuration(server_response.details);
      } else {
        setAverageCallDuration("404");
      }
    };

    const getAverageCallDurationToday = async () => {
      const server_response = await ajaxCallStation.averageCallDurationToday();
      if (server_response.status === "OK") {
        setAverageCallDurationToday(server_response.details);
      } else {
        setAverageCallDurationToday("404");
      }
    };

    const getLongestCallDuration = async () => {
      const server_response = await ajaxCallStation.longestCallDuration();
      if (server_response.status === "OK") {
        setLongestCallDuration(server_response.details);
      } else {
        setLongestCallDuration("404");
      }
    };

    const getTwilioLogsList = async (currentPage) => {
        if (isFirstLoad) setLoading(true);
      
        try {
          const server_response = await ajaxCallStation.listTwilioCallLogs(currentPage);
          if (server_response.status === "OK") {
            setTwilioMeta(server_response.details.meta.list_of_pages);
            setTwilioLogsList(server_response.details.list || []);
          } else {
            setTwilioLogsList([]);
          }
        } catch (error) {
          console.error("Error fetching Twilio logs:", error);
        } finally {
          setLoading(false);
          if (isFirstLoad) setIsFirstLoad(false); // Disable loader after the first load
        }
    };

    const getZegoLogsList = async (currentPage) => {
        if (isFirstLoad) setLoading(true);
      
        try {
          const server_response = await ajaxCallStation.listZegoCallLogs(currentPage);
          if (server_response.status === "OK") {
            setZegoMeta(server_response.details.meta.list_of_pages);
            setZegoLogsList(server_response.details.list || []);
          } else {
            setZegoLogsList([]);
          }
        } catch (error) {
          console.error("Error fetching Zego logs:", error);
        } finally {
          setLoading(false);
          if (isFirstLoad) setIsFirstLoad(false); 
        }
    };

    const searchTwilioLogs = async (e) => {
        if (e) {
          e.preventDefault();
        }
        setLoading2(true);
        setIsTwilioSearching(true);
        const server_response = await ajaxCallStation.searchTwilioLogs(twilioPage, twilioCaller, twilioCallee, twilioStartDate, twilioEndDate);
        setLoading2(false);
        if (server_response.status === "OK") {
          setTwilioMeta(server_response.details.meta.list_of_pages);
          setTwilioLogsList(server_response.details.list || []);
        } else {
          setTwilioLogsList([]);
        }     
    };

    const searchZegoLogs = async (e) => {
        if (e) {
          e.preventDefault();
        }
        setLoading2(true);
        setIsZegoSearching(true);
        const server_response = await ajaxCallStation.searchZegoLogs(zegoPage, zegoCaller, zegoCallee, zegoStartDate, zegoEndDate);
        setLoading2(false);
        if (server_response.status === "OK") {
          setZegoMeta(server_response.details.meta.list_of_pages);
          setZegoLogsList(server_response.details.list || []);
        } else {
          setZegoLogsList([]);
        }     
    };

    
  return (
    <CallContext.Provider value={
      {
        twilioLogsList,
        zegoLogsList,
        twilioCallee,
        zegoCallee,
        twilioCaller,
        zegoCaller,
        twilioEndDate,
        zegoEndDate,
        twilioStartDate,
        zegoStartDate,
        twilioMeta,
        zegoMeta,
        twilioPage,
        zegoPage,
        isTwilioSearching,
        isZegoSearching,
        isFirstLoad,
        isRealTimeEnabled,
        loading,
        loading2,
        logsNumber,
        logsThisMonth,
        logsThisWeek,
        buzzVoiceCallsToday,
        buzzVideoCallsToday,
        gsmCallsToday,
        completedCallsToday,
        missedCallsToday,
        completedCalls,
        missedCalls,
        totalCallsPerCategory,
        averageCallDuration,
        averageCallDurationToday,
        longestCallDuration,
        searchTwilioLogs,
        searchZegoLogs,
        getTwilioLogsList,
        getZegoLogsList,
        setTwilioPage,
        setZegoPage,
        setIsRealTimeEnabled,
        setTwilioCallee,
        setZegoCallee,
        setTwilioCaller,
        setZegoCaller,
        setTwilioEndDate,
        setZegoEndDate,
        setTwilioStartDate,
        setZegoStartDate,
        setIsTwilioSearching,
        setIsZegoSearching,
        getLogsNumber,
        getLogsThisMonth,
        getLogsThisWeek,
        getCallsPerCategory,
        getBuzzVoiceCallsToday,
        getBuzzVideoCallsToday,
        getGsmCallsToday,
        getCompletedCallsToday,
        getMissedCallsToday,
        getAverageCallDuration,
        getAverageCallDurationToday,
        getCompletedCalls,
        getMissedCalls,
        getLongestCallDuration

      }
    }>
    {props.children}
    </CallContext.Provider>
  );
    
}

export default CallContext;