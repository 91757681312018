import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {

  async fetchVendorList(data) 
  {
    let response = await apiCall("vendor/list", data);
    return response;
  },

  async createVendor(data) 
  {
    let response = await apiCall("vendor/add", data);
    return response;
  },

  async fetchVendorInfo(vendor_id) 
  {
    let data = {
      "vendor_id":vendor_id
    };
    let response = await apiCall("vendor/profile", data);
    return response;
  },
};
