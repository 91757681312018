import React, { useContext, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import { Toaster} from 'react-hot-toast'
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import VendorContext from '../../../../Context/VendorContext';
import Pagination from '../../../../Components/Common/Pagination';
import SummaryCount from '../../../../Components/Vendors/SummaryCount';
import AppContainer from '../../../../Components/Structure/AppContainer';
import VendorTransactions from '../../../../Components/Vendors/VendorTransactions';

const VendorProfilePage = props => {

  const {vendorProfile, vendorWalletBalance, setVendorId, setVendorUserId , vendorTransactions,
     loading2, meta, page, setPage} = useContext(VendorContext);
  const {id, user_id} = useParams();
  
  useEffect(() => {
    setVendorId(id);
  }, [id, setVendorId]);

  useEffect(() => {
    setVendorUserId(user_id);
  }, [user_id, setVendorUserId]);

  const handlePagination = (newPage) => {
    if (newPage > 0 && newPage <= meta.length) {
      setPage(newPage);
    }
  };

  return (
    <AppContainer title={"Vendor Profile"} >
      <Toaster
          position="top-center"
          reverseOrder={false}
      />
        
      <section class="section profile">
        <div class="row">
          <div class="col-xl-4">
            <div class="col-lg-12">
              <div class="cards">
                <div class="card-body profile-card pt-4 d-flex flex-column gradient-my-blue">
                  <div class="main-img-user mb-2">
                    <img
                      alt="avatar"
                      style={{ height: "90px" }}
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/figure/user55.png"
                      }
                    />
                  </div>
                  <h3 style={{ color: "white" }}>
                    {vendorProfile.full_name}
                  </h3>
                  <h5 style={{ color: "white" }}>{vendorProfile.username}</h5>
                  
                </div>
              </div>
            </div>

            
          </div>

          <div className="col-xl-8">
            <div className="row">
              <SummaryCount walletBalance={vendorWalletBalance}/>

              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body pt-3">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                      <Row>
                        <Col sm={12}>
                          <Nav variant="pills" className="flex-row mb-1">
                            <Nav.Item>
                              <Nav.Link size="sm" eventKey="first">
                                Transaction History{" "}
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>

                        <Col sm={12}>
                          <Tab.Content>

                            <Tab.Pane eventKey="first">

                              <VendorTransactions walletTransactions={vendorTransactions} loading3={loading2}/>
                              {/* <Pagination currentPage={page} totalPages={meta.length} onPageChange={handlePagination}/> */}
                              
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </AppContainer>
  )
}

export default VendorProfilePage;