import React, { useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import AppContainer from "../../Components/Structure/AppContainer";
import BuzztimeLoadTransactions from "../../Components/Transactions/BuzztimeLoadTransactions";
import AccountActivations from "../../Components/Transactions/AccountActivations";
import CallTransactions from "../../Components/Transactions/CallTransactions";
import MessageTransactions from "../../Components/Transactions/MessageTransactions";
import StatisticsContext from "../../Context/StatisticsContext";
import ChatCategoriesChart from "../../Components/Transactions/ChatCategoriesChart";
import AirtimePurchasesChart from "../../Components/Transactions/AirtimePurchasesChart";
import AccountActivationsChart from "../../Components/Transactions/AccountActivationsChart";

function BuzztimeChargesPage() {
    const {buzzTimeTotal, accountActivationTotal, callChargesTotal, messageChargesTotal, getMessageChargesTotal,
        getCallChargesTotal, getBuzzTimeTotal, getAccountActivationsTotal} = useContext(StatisticsContext);
   
     useEffect(() => {
       getBuzzTimeTotal();
       getAccountActivationsTotal();
       getCallChargesTotal();
       getMessageChargesTotal();
     }, []);
 
  return (
    <AppContainer title="Buzztime Charges">

        <div className="row">
            <div className="col-lg-8">
                <div className="row">
                    <div className="col-lg-12">
                        <div class="card custom-card " style={{paddingBottom:"10px"}}>
                            <div className="card-body map-card gradient-my-blue">
                            <div class="item-title mb-2" style={{color:"white"}}><b>TOTAL </b></div>
                            <div class="row" >
                                <div class="col-xl-3 col-lg-12 col-sm-6 pr-0 pl-0 border-right">
                                    <div class="text-center">
                                    <h2 class="mb-1 number-font" style={{color:"white"}}><span class="counter"><small>UGX. </small>{buzzTimeTotal ? buzzTimeTotal.total_buzztime_c : "..."}</span></h2>
                                    <p class="mb-0 text-light"> Buzztime Purchases</p>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-12 col-sm-6 pr-0 pl-0 border-right" >
                                <div class="text-center" >
                                    <h2 class="mb-1 number-font" style={{color:"white"}}><span class="counter"><small>UGX. </small>{accountActivationTotal ? accountActivationTotal.total_account_activations_c : "..."}</span></h2>
                                    <p class="mb-0 text-light"> Account Activations</p>
                                </div>
                                </div>
                                <div class="col-xl-3 col-lg-12 col-sm-6 pr-0 pl-0 border-right" >
                                <div class="text-center" >
                                    <h2 class="mb-1 number-font" style={{color:"white"}}><span class="counter"><small>UGX. </small>{callChargesTotal ? callChargesTotal.total_call_charges_c : "..."}</span></h2>
                                    <p class="mb-0 text-light"> Call Charges</p>
                                </div>
                                </div>
                                <div class="col-xl-3 col-lg-12 col-sm-6 pr-0 pl-0" >
                                <div class="text-center" >
                                    <h2 class="mb-1 number-font" style={{color:"white"}}><span class="counter"><small>UGX. </small>{messageChargesTotal ? messageChargesTotal.total_message_charges_c : "..."}</span></h2>
                                    <p class="mb-0 text-light"> Message Charges</p>
                                </div>
                                </div>                
                            </div>
                            </div>
                        </div> 
                    </div>
                    
                    <div className="col-lg-12 col-md-12 mt-3">
                <div className="card height-auto">
                    <div className="card-body">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col sm={12}>
                                    <Nav variant="pills" className="flex-row mb-1">
                                        <Nav.Item>
                                            <Nav.Link size="sm" eventKey="first">
                                            Buzz Time Load{" "}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link size="sm" eventKey="second">
                                            Account Activations{" "}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link size="sm" eventKey="third">
                                            Phone Charges{" "}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link size="sm" eventKey="fourth">
                                            Message Charges{" "}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link size="sm" eventKey="fifth">
                                            Admin Refund
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>

                                <Col sm={12}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <BuzztimeLoadTransactions/> 
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <AccountActivations/>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <CallTransactions/>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="fourth">
                                            <MessageTransactions/> 
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="fifth">
                                             
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>  
      
                    </div>
                </div>
                    </div> 
                </div>               
            </div>

            <div className="col-lg-4">
                <div className="row">
                    <ChatCategoriesChart/>

                    <AirtimePurchasesChart/>

                    <AccountActivationsChart/>
                        
                  
                    
                   
                </div>               
            </div>


     
            
        </div>
     
    </AppContainer>
  );
}

export default BuzztimeChargesPage;