import React from "react";
import MMTransactions from "../../Components/Transactions/MMTransactions";
import AppContainer from "../../Components/Structure/AppContainer";

function MobileMoneyPage() {
 
  return (
    <AppContainer title="Mobile Money Transactions">

        <div className="row">
            <div className="col-lg-12 col-md-12 mt-3">
            <div className="card custom-card">
                    <div className="card-body map-card">
                        <MMTransactions/>
                    
                    </div>
                </div>
            </div>
        </div>
     
    </AppContainer>
  );
}

export default MobileMoneyPage;