import { useEffect, useState } from "react"
import { toast } from 'react-hot-toast';
import Loader from "../Common/Loader";
import SystemModal from "../Common/SystemModal";
import ajaxUser from "../../util/remote/ajaxUser";

const EditPicture=(props)=>{

    const [loading, setLoading] = useState(false)
    const [profilePicture, setProfilePicture] = useState("");

    const handleUpdate = async(e) =>{
        e.preventDefault()
        if(profilePicture.length>0){
            setLoading(true)
            const server_response = await ajaxUser.uploadPicture(props.userID, profilePicture);
     
            setLoading(false);
            if(server_response.status==="OK"){
                toast.success(server_response.message);
                props.g()
            }
            else{
                toast.error(server_response.message); 
            }
        }
        else{
            toast.error("Please upload a picture!")
        }  
    }
    
    const changePicture = (e) => {
        e.preventDefault();
    
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
    
        reader.onload = (e) => {
          const newItem = { file: e.target.result };
   
          setProfilePicture(e.target.result);
        };
    };

    const RenderFooter=(controls)=>{

        if(loading){
            return <Loader/>
        }else{

            return <> 
                    <button className="btn-fill-md text-light bg-martini shadow-martini" type="button" onClick={controls.close}>Close</button>
                    <button 
                        type="button" 
                        className={`btn-fill-md text-light bg-dodger-blue`} 
                        onClick={handleUpdate}>Save Image<i class="fas fa-check mg-l-15"></i></button>
                    </>
        }
    }

    return(
        <SystemModal
            title="Edit Profile Picture"
            id="model-edit-profile-pic"
            size="md"
            footer={RenderFooter}
        >
            
            <div className="mb-4 form-group border-1">
                <label htmlFor="">Select Picture</label>
                <input
                  type="file"
                  onChange={(e) => changePicture(e)}

                  className="form-control"
                />
            </div>
            <div className="mb-4 form-group border-1">
            <label htmlFor="">Image Preview</label> <br/>
                <img
                            alt="Image Preview"
                            style={{ height: "90px" }}
                            class="img-thumbnail"
                            src={profilePicture}
                          />
            </div>
       
        </SystemModal>
    )
}

export default EditPicture
