import React, { useEffect, useState } from 'react';
import ajaxBank from '../util/remote/ajaxBank';
import ajaxCallStation from '../util/remote/ajaxCallStation';

const StatisticsContext = React.createContext();

export const StatisticsConsumer = StatisticsContext.Consumer;

export const StatisticsProvider = (props)=> {

  const [buzzTimeTotal, setBuzzTimeTotal] = useState(false);
  const [accountActivationTotal, setAccountActivationTotal] = useState(false);
  const [callChargesTotal, setCallChargesTotal] = useState(false);
  const [messageChargesTotal, setMessageChargesTotal] = useState(false);
  const [buzzChatsPerCategory, setBuzzChatsPerCategory] = useState(false);
  const [averageChatSize, setAverageChatSize] = useState(false);
  const [gsmCount, setGSMCount] = useState(false);
  const [averageGSMSize, setAverageGSMSize] = useState(false);

  useEffect(() => {
        getAverageChatSize();
        getBuzzChatsPerCategory();
        getGSMCount();
        getAverageGSMSize();
  }, []);

  const getBuzzChatsPerCategory = async () => {
    const server_response = await ajaxCallStation.buzzMessagesPerCategory();
    if (server_response.status === "OK") {
      setBuzzChatsPerCategory(server_response.details);
    } else {
      setBuzzChatsPerCategory("404");
    }
  };

  const getGSMCount = async () => {
    const server_response = await ajaxCallStation.countGSMMessages();
    if (server_response.status === "OK") {
      setGSMCount(server_response.details);
    } else {
      setGSMCount("404");
    }
  };

  const getAverageChatSize = async () => {
    const server_response = await ajaxCallStation.averageBuzzChatSize();
    if (server_response.status === "OK") {
      setAverageChatSize(server_response.details);
    } else {
      setAverageChatSize("404");
    }
  };

  const getAverageGSMSize = async () => {
    const server_response = await ajaxCallStation.averageGSMSize();
    if (server_response.status === "OK") {
      setAverageGSMSize(server_response.details);
    } else {
      setAverageGSMSize("404");
    }
  };


  const getBuzzTimeTotal = async () => {
    const server_response = await ajaxBank.fetchBuzzTimeTotal();
    if (server_response.status === "OK") {
      setBuzzTimeTotal(server_response.details);
    } else {
      setBuzzTimeTotal("404");
    }
  };

  const getAccountActivationsTotal = async () => {
    const server_response = await ajaxBank.fetchAccountActivationsTotal();
    if (server_response.status === "OK") {
      setAccountActivationTotal(server_response.details);
    } else {
      setAccountActivationTotal("404");
    }
  };

  const getCallChargesTotal = async () => {
    const server_response = await ajaxBank.fetchTotalCallCharges();
    if (server_response.status === "OK") {
      setCallChargesTotal(server_response.details);
    } else {
      setCallChargesTotal("404");
    }
  };

  const getMessageChargesTotal = async () => {
    const server_response = await ajaxBank.fetchTotalMessageCharges();
    if (server_response.status === "OK") {
      setMessageChargesTotal(server_response.details);
    } else {
      setMessageChargesTotal("404");
    }
  };


    
  return (
    <StatisticsContext.Provider value={
      {
        buzzTimeTotal,
        accountActivationTotal,
        callChargesTotal,
        messageChargesTotal,
        buzzChatsPerCategory,
        averageChatSize,
        gsmCount,
        averageGSMSize,
        getBuzzTimeTotal,
        getAccountActivationsTotal,
        getCallChargesTotal,
        getMessageChargesTotal,
        getAverageChatSize,
        getBuzzChatsPerCategory,
        getGSMCount,
        getAverageGSMSize
        

      }
    }>
    {props.children}
    </StatisticsContext.Provider>
  );
    
}

export default StatisticsContext;