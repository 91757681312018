import React, { useContext, useEffect} from "react";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import TableHeader from "../Common/TableHeader";
import Loader from "../Common/Loader";
import TransactionsContext from "../../Context/TransactionsContext";
import TransactionsTable from "../Common/Transactions/TransactionsTable";
import Pagination from "../Common/Pagination";
import TransactionsSearchForm from "../Common/Transactions/TransactionsSearchForm";

function PMoneyTransactions() {
  const {pmTransactions, pmMeta, pmPage, pmSearchTerm, pmStartDate, pmEndDate, loading,
    loading2, setPmSearchTerm, setPmStartDate, setPmEndDate, setPmPage, fetchPMTransactions, searchPMTransactions } = useContext(TransactionsContext);

  const resetFilters = (e) => {
    e.preventDefault();
    setPmSearchTerm("");
    setPmStartDate("");
    setPmEndDate("");
    setPmPage(1);
    fetchPMTransactions(1);
  };

  const exportToPDF = () => {
    const pdf = new jsPDF("p", "pt", "a4");
    const columns = ["Date & Time", "User", "Transaction Number", "Amount"];
    const data = pmTransactions.map(item => [
      `${item.created_at?.short_date} ${item.created_at?.time}`,
      `${item.user} (${item.username})`,
      item.phone_number,
      `UGX. ${item.amount}`,
    ]);

    pdf.autoTable({ head: [columns], body: data });
    pdf.save("pkt_money_data.pdf");
  };

  const handlePagination = (newPage) => {
    if (newPage > 0 && newPage <= pmMeta.length) {
      setPmPage(newPage);
    }
  };

  const headers = [
    { label: "Transaction Date", key: "created_at" },
    { label: "Transaction Number", key: "transaction_number" },
    { label: "Amount", key: "amount" },
    { label: "Transactors", key: "transactors"},
    { label: "Transaction Type", key:"account"}

  ];

  useEffect(() => {
    if (pmSearchTerm || pmStartDate || pmEndDate) {
      searchPMTransactions();
    } else {
      fetchPMTransactions(pmPage);
    }
  }, [pmPage]);

  return (
    <>
      <div className="heading-layout1 mg-b-5">
        <TableHeader
            title="Pocket Money Transactions"
            subtitle="List of all the pocket money transactions made sorted by the most recent"
        />
        <div className="dropdown">
          <Link className="dropdown-toggle" role="button" data-toggle="dropdown">...</Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link className="dropdown-item" onClick={exportToPDF}>
              <i className="fas fa-file-export"></i>Export
            </Link>
          </div>
        </div>
      </div>

      <TransactionsSearchForm searchTerm={pmSearchTerm} setSearchTerm={setPmSearchTerm} startDate={pmStartDate}
         setStartDate={setPmStartDate} endDate={pmEndDate} setEndDate={setPmEndDate} searchTransactions={searchPMTransactions}
         setTransactions={resetFilters} setPage={setPmPage} searchTermPlaceholder="Enter transaction number..."/>

      <div className="border-top mt-3"></div>
      <div className="table-responsive">
        {loading || loading2 ? (
          <Loader />
        ) : (
          <TransactionsTable headers={headers} data={pmTransactions} isPMTransaction={true}/>
        )}
      </div>

      <Pagination currentPage={pmPage} totalPages={pmMeta.length} onPageChange={handlePagination}/>

    </>
  );
}

export default PMoneyTransactions;