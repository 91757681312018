import React, { useContext, useEffect} from "react";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import TableHeader from "../../Components/Common/TableHeader";
import Loader from "../../Components/Common/Loader";
import TransactionsContext from "../../Context/TransactionsContext";
import TransactionsTable from "../Common/Transactions/TransactionsTable";
import Pagination from "../Common/Pagination";
import TransactionsSearchForm from "../Common/Transactions/TransactionsSearchForm";

function MMTransactions() {
  const {mmTransactions, mmMeta, mmPage, mmSearchTerm, mmStartDate, mmEndDate, loading,
    loading2, setMmSearchTerm, setMmStartDate, setMmEndDate, setMmPage, fetchMMTransactions, searchMMTransactions } = useContext(TransactionsContext);

  const resetFilters = (e) => {
    e.preventDefault();
    setMmSearchTerm("");
    setMmStartDate("");
    setMmEndDate("");
    setMmPage(1);
    fetchMMTransactions(1);
  };

  const exportToPDF = () => {
    const pdf = new jsPDF("p", "pt", "a4");
    const columns = ["Date & Time", "User", "Transaction Number", "Amount"];
    const data = mmTransactions.map(item => [
      `${item.created_at?.short_date} ${item.created_at?.time}`,
      `${item.user} (${item.username})`,
      item.phone_number,
      `UGX. ${item.amount}`,
    ]);

    pdf.autoTable({ head: [columns], body: data });
    pdf.save("mm_data.pdf");
  };

  const handlePagination = (newPage) => {
    if (newPage > 0 && newPage <= mmMeta.length) {
      setMmPage(newPage);
    }
  };

  const headers = [
    { label: "Transaction Date", key: "created_at" },
    { label: "User Details", key: "student" },
    { label: "Transaction Number", key: "phone_number" },
    { label: "Amount", key: "amount" },
    { label: "Transaction Type", key:"account"},
    { label: "Client Ref", key:"internal_ref"}

  ];

  useEffect(() => {
    if (mmSearchTerm || mmStartDate || mmEndDate) {
      searchMMTransactions();
    } else {
      fetchMMTransactions(mmPage);
    }
  }, [mmPage]);

  return (
    <>
      <div className="heading-layout1 mg-b-5">
        <TableHeader
          title="Mobile Money Transactions"
         subtitle="List of all the mobile money transactions made sorted by the most recent" />
        <div className="dropdown">
          <Link className="dropdown-toggle" role="button" data-toggle="dropdown">...</Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link className="dropdown-item" onClick={exportToPDF}>
              <i className="fas fa-file-export"></i>Export
            </Link>
          </div>
        </div>
      </div>

      <TransactionsSearchForm searchTerm={mmSearchTerm} setSearchTerm={setMmSearchTerm} startDate={mmStartDate}
         setStartDate={setMmStartDate} endDate={mmEndDate} setEndDate={setMmEndDate} searchTransactions={searchMMTransactions}
         setTransactions={resetFilters} setPage={setMmPage} searchTermPlaceholder="Enter first or last name of user..."/>

      <div className="border-top mt-3"></div>
      <div className="table-responsive">
        {loading || loading2 ? (
          <Loader />
        ) : (
          <TransactionsTable headers={headers} data={mmTransactions} isMMTransaction={true}/>
        )}
      </div>

      <Pagination currentPage={mmPage} totalPages={mmMeta.length} onPageChange={handlePagination}/>

    </>
  );
}

export default MMTransactions;