import React from "react";

function DashboardStatistics({schoolsNumber, contactsNumber, studentsNumber}) {

    return (
            <div class="col-12-xxxl col-12">
                <div className="row">
                    <div class="col-lg-4">
                        <div class="dashboard-summery-one mg-b-20 gradient-my-blue">
                            <div class="row align-items-center">
                                <div class="col-4">
                                    <div class="item-icon bg-light">
                                        <img alt="avatar" style={{height:"48px"}} src={process.env.PUBLIC_URL + "/assets/img/figure/school.png"}/>
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="item-content">
                                        <div class="item-title" style={{color:"white"}}>Schools Registered</div>
                                        <div class="item-number" style={{color:"white"}}><span class="counter">{schoolsNumber ? schoolsNumber.total_p : "..."}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="dashboard-summery-one mg-b-20 gradient-my-blue" >
                            <div class="row align-items-center">
                                <div class="col-4">
                                    <div class="item-icon bg-light">
                                        <img alt="avatar" style={{height:"48px"}} src={process.env.PUBLIC_URL + "/assets/img/figure/students.png"}/>
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="item-content">
                                        <div class="item-title" style={{color:"white"}}>Students Registered</div>
                                        <div class="item-number" style={{color:"white"}}><span class="counter">{studentsNumber ? studentsNumber.total_p : "..."}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4">
                        <div class="dashboard-summery-one mg-b-20 gradient-my-blue">
                            <div class="row align-items-center">
                                <div class="col-4">
                                    <div class="item-icon bg-light">
                                        <img alt="avatar" style={{height:"48px"}} src={process.env.PUBLIC_URL + "/assets/img/figure/phone-call.png"}/>
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="item-content">
                                        <div class="item-title" style={{color:"white"}}>Contacts Registered</div>
                                        <div class="item-number" style={{color:"white"}}><span class="counter">{contactsNumber ? contactsNumber.total_p : "..."}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
               </div>
            </div>

            
        
        
    );
}

export default DashboardStatistics;
